// 公用函数
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.max-width(@maxWidth) {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: @maxWidth;
}
.transition-all() {
    transition: all .3s ease-in-out;
}
.transition-setting(@item) {
    transition: @item .3s ease-in-out;
}
.dot {
    width: 1rem;
    height: 1rem;
    margin: 3rem 0.2rem;
    background: var(--theme-color);
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
}

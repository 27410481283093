@import url("./base.less");
@import url("./functions.less");

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
    font-family: "思源黑体 Normal";
    font-weight: 350;
    src: url("//at.alicdn.com/wf/webfont/sNNFhXLgxEoE/fvdCmTuR6pCa.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/sNNFhXLgxEoE/NKDFh2dO4DjO.woff") format("woff");
    font-display: swap;
}

:root {
    --opacity: 60%;
    --shallow-theme-color: rgb(156, 192, 245);
    --theme-color: rgb(93, 124, 172);
    --background-color: rgb(255, 255, 255);
    --background-color-opacity: rgba(255, 255, 255, var(--opacity));
    --text-color-primary: rgb(51, 51, 51);
    --text-color-secondary: rgb(102, 102, 102);
    --hightlight: rgb(255, 255, 255);
    --shadow: rgba(51, 51, 51, 0.08);
    --border: rgba(51, 51, 51, .1);
    --dark-mode: rgb(255, 203, 107);
}

// 夜间模式
.bg-black {
    --background-color: rgb(51, 51, 51);
    --background-color-opacity: rgba(51, 51, 51, var(--opacity));
    --text-color-primary: rgb(255, 255, 255);
    --text-color-secondary: rgb(170, 170, 170);
    --hightlight: rgb(255, 255, 255);
    --shadow: rgba(255, 255, 255, 0.08);
    --dark-mode: rgb(255, 255, 255);
    --border: rgba(255, 255, 255, .1);
}

#cover {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    opacity: 1;
    transform: scale(1);
    z-index: 9999;

    &.loaded {
        opacity: 0;
        transform: scale(1.2);
        pointer-events: none;
        transition: all .3s ease-in;
    }
}


body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color-primary);
    font-family: Arial, Helvetica, sans-serif, "思源黑体 Normal";
    .transition-all();

    &.onload {
        overflow: hidden;
    }
}

// 导航栏
#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--background-color);
    backdrop-filter: blur(20px);
    z-index: 999;
    .transition-all();

    .header-container {
        .flex-between();
        .max-width(unset);
        padding: 1.5rem 3rem;
        .transition-setting(padding);

        img {
            height: 2rem;
        }
    }

    &.onscroll {
        background-color: var(--background-color-opacity);
        box-shadow: 0 0 10px var(--shadow);

        .header-container {
            padding: 1rem 3rem;
        }
    }

    .navigation {

        a {
            letter-spacing: 0.2rem;
            color: var(--text-color-primary);
            .transition-all();

            &.current {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 1.5rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: var(--theme-color);
                }
            }
        }

        &>*:not(:last-child) {
            margin-right: 3rem;
        }

        &:hover>a:not(:hover) {
            opacity: var(--opacity);
        }

        .navigation-toggle {
            display: none;
        }

    }

    .dark-mode {
        color: var(--dark-mode);
        font-size: 1.2rem;
    }
    .navigation-toggle {
        display: none;
    }
}

#main {
    flex: 1 0 auto;
    .max-width(1200px);
    margin-top: 6rem;
}

// widget
@import url("./widget.less");

// 页面描述
.page-title {
    margin: 1rem 0 3rem;
    text-align: center;

    h3 {
        margin: 0;
        font-weight: normal;

        &::before {
            content: '#';
            color: var(--theme-color);
            margin-right: 1rem;
        }

        &::after {
            content: '#';
            color: var(--theme-color);
            margin-left: 1rem;
        }
    }
}

// 幻灯片卡片
.swipe-card {
    position: relative;
    color: var(--hightlight);
    overflow: hidden;

    &::before {
        content: '';
        float: left;
        padding-top: calc((9 / 18) * 100%);
    }

    &::after {
        content: '';
        .absolute-center();
        background-image: linear-gradient(45deg, var(--shallow-theme-color), var(--theme-color));
        width: 90%;
        height: 90%;
        border-radius: 25px;
        z-index: -1;
        .transition-all();
    }

    .swipe-container {
        .absolute-center();
        .flex-between();
        width: 90%;
        height: 90%;
        .transition-all();
    }

    .swipe-content {
        flex: 0 1 auto;
        max-width: 432px;
        margin-left: 3rem;

        .swipe-title {
            font-size: 2.5rem;
            color: var(--hightlight);

            h2 {
                display: inline;
                background-image: linear-gradient(to bottom, transparent 90%, var(--theme-color) 10%);
                background-size: 0 100%;
                background-repeat: no-repeat;
                background-position: left;
                .transition-setting(background-size);
            }
        }

        .swipe-author {
            font-size: 1.5rem;
        }
    }

    .swipe-people {
        flex: 0 0 auto;
        margin-top: var(--margin-top);
        line-height: 1;
        .transition-all();

        .swipe-img {
            height: calc(var(--height) - 20px);
            border-end-end-radius: 25px;
            .transition-all();
        }
    }

    &:hover {

        &::after,
        .swipe-container {
            width: 100%;
            height: 100%;
        }

        .swipe-people {
            margin-top: 0px;

            .swipe-img {
                height: var(--height);
            }
        }

        .swipe-title {

            h2 {
                background-size: 100% 100%;
            }
        }
    }
}

// 瀑布流
.masonry {
    @gap-size: 1rem;
    column-count: 3;
    column-gap: @gap-size;

    .masonry-item {
        position: relative;
        display: block;
        line-height: 0;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: @gap-size;

        .masonry-image {
            width: 100%;
            filter: brightness(0.8);
            .transition-all();
        }

        .masonry-description {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: .5rem 1rem;
            line-height: 1.2;
            color: var(--hightlight);
            background-image: linear-gradient(45deg, var(--shallow-theme-color), var(--theme-color));
            background-size: 100% 0;
            background-position-y: bottom;
            .transition-all();

            span {
                display: -webkit-inline-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &:hover {

            .masonry-image {
                transform: scale(1.2);
                filter: brightness(1);
            }

            .masonry-description {
                padding: 1rem 1rem;
                background-size: 100% 100%;
            }
        }
    }
}

// 视频列表
.video-list {
    grid-template-columns: repeat(3, 1fr);

    .video-title {
        font-size: 1rem !important;
    }
}

// 文章列表
.articles {
    grid-template-columns: 1fr;
    .max-width(960px);

    .posts-item-cover {
        margin-right: 1rem;
    }
    .posts-item-detail {
        justify-content: flex-start;

        .posts-item-description {
            color: var(--text-color-primary);
            flex: 1 1 auto;
        }
    }
}

// 文章单页
.post {

    &-header {
        position: relative;
        border-radius: 15px 15px 0 0;
        overflow: hidden;

        &::before {
            content: '';
            float: left;
            padding-top: calc((8 / 16) * 100%);
        }
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0; left: 0;
            background-image: linear-gradient(to bottom, transparent 40%, var(--background-color) 98%);
            z-index: 1;
        }

        .post-cover {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 0;
            .transition-all();
        }
        .post-detail {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 90%;
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;

            .post-title {
                margin: 0;
                font-size: 2rem;;
                color: var(--text-color-primary);
            }
        }
    }

    &-icon {
        color: var(--text-color-secondary);
        font-size: 1rem;
        margin-bottom: 0.5rem;

        span {
            position: relative;
            margin-right: 1rem;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -.6rem;
                width: 2px;
                height: 2px;
                background-color: var(--text-color-secondary);
                transform: translateY(-50%);
            }
        }
    }

    &-content {
        .max-width(90%);
        font-size: 18px;
        margin-top: -1rem;

        p {
            line-height: 1.4;

            img {
                display: block;
                border-radius: 5px;
                margin: 0 auto;
                max-width: 100%;
            }

            &::first-letter {
                margin-left: 2rem;
            }
        }
    }
}

// 分页
.pagination {
    .flex-center();
    flex-wrap: wrap;
    margin: 2rem 0 1rem;

    .pagination-buttons {
        .flex-center();
    }

    &-button {
        padding: .5rem 1rem;
        border-radius: 9999px;
        border: 1px solid var(--border);
        line-height: 1;
        .transition-all();

        &:hover {
            border-color: var(--theme-color);
        }

        &:nth-child(2) {
            margin-left: 1rem;
        }
    }


    @keyframes bouncing-loader {
        from {
            opacity: 1;
            transform: translateY(0);
        }

        to {
            opacity: 0.1;
            transform: translateY(-1rem);
        }
    }
    .bouncing-loader {
        width: 100%;
        display: flex;
        justify-content: center;

        & > .dot {
            width: 1rem;
            height: 1rem;
            margin: 0 0.2rem;
            background: var(--theme-color);
            border-radius: 50%;
            animation: bouncing-loader 0.6s infinite alternate;

            &:nth-child(2) {
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation-delay: 0.4s;
            }
        }
    }
}

// 返回顶部
#go-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;

    a {
        .flex-center();
        font-size: 1.5rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 5px;
        color: var(--text-color-secondary);
        border: 1px solid var(--border);
        background-color: var(--background-color);
    }
}

// 页面加载
#go {
    flex-direction: column;
    .flex-center();
    padding: 0 2rem;
    min-height: 100vh;
    text-align: center;
    
    @keyframes image-breath {
        from {
            transform: scale(.8);
        }
        to {
            transform: scale(1.2);
        }
    }
    img {
        margin-bottom: 2rem;
        animation: image-breath 1s infinite alternate;
    }
}

#footer {
    margin-top: 3rem;
    padding: 1rem 0;
    width: 100%;
    background-color: var(--shadow);

    .footer-container {
        .max-width(1200px);

        p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            font-size: .9rem;
        }
    }
    

    &.index p {
        width: 90%;
    }
}

@import url("./media.less");

// 互通全局样式
*,
*::after,
*::before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
}
a {
    color: var(--text-color-primary);

    &:hover {
        color: var(--theme-color);
    }
}

.pswp__custom-caption {
    position: absolute;
    padding: 1rem 2rem;
    left: 50%;
    bottom: 0;
    width: 100%;
    font-size: 16px;
    transform: translateX(-50%);
    color: var(--hightlight);
    background: var(--theme-color);

    a {
        color: var(--hightlight);
        text-decoration: underline;
    }
    span {
        display: block;
        max-width: 960px;
    }
}

.hidden-caption-content {
    display: none;
}
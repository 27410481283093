// 平板
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {

    // 幻灯片卡片
    .swipe-card {
        &:hover {
            &::after {
                border-radius: 0;
            }

            img {
                border-end-end-radius: 0!important;
            }
        }

        h2 {
            font-size: 2.5rem!important;
        }
    }

    // 视频 widget
    .videos {
        grid-template-columns: repeat(4, 1fr);
        .video-item:nth-child(5) {
            display: none;
        }
    }

    #main:not(.index),
    .footer-container {
        width: 90%!important;
    }

    .articles h3 span {
        -webkit-line-clamp: 1!important;
    }

}

// 手机
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

    // 导航栏
    #header {

        .header-container {
            .max-width(90%);
            padding: 1.5rem 0;
            .transition-setting(padding);

            img {
                height: 1.5rem;
            }
        }

        &.onscroll .header-container {
            padding: 1rem 0;
        }

        .navigation {
            position: fixed;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 234px;
            height: 100vh;
            padding: 2rem;
            background-color: var(--background-color);
            box-shadow: 5px 0 10px var(--shadow);
            transform: translateX(-300px);
            pointer-events: none;
            .transition-all();

            &.show {
                transform: translateX(0);
                pointer-events: all;
            }

            a {
                margin: 0!important;
                padding: .8rem 1rem;
                font-size: 1.2rem;
                border-radius: 5px;
                line-height: 1;

                &.current {
                    color: var(--hightlight);
                    background-color: var(--theme-color);
                }

                &:not(:first-child) {
                    margin-top: .5rem!important;
                }

                &::after {
                    display: none;
                }
            }
        }

        .header-icon-list {
            display: flex;
            gap: 1rem;

            a {
                font-size: 1.5rem!important;

                &.navigation-toggle {
                    color: var(--text-color-secondary);
                    display: block!important;
                }
            }
        }
    }

    .widget-title h2 {
        font-size: 1.5rem;
    }

    // 幻灯片卡片
    .swipe-card {
        &:hover {
            &::after {
                border-radius: 0;
            }

            img {
                border-end-end-radius: 0!important;
            }
        }

        .swipe-content {
            margin-left: 2rem;
        }
        
        .swipe-title {
            &, & h2 {
                font-size: 1.4rem!important;
                line-height: 1.2;
            }
        }
        .swipe-author {
            font-size: 1.1rem!important;
            margin: 1rem 0!important;
        }

    }

    // 视频 widget
    .videos {
        grid-template-columns: repeat(3, 1fr);
        .video-item:nth-child(4), .video-item:nth-child(5) {
            display: none;
        }
    }

    // 相册 widget
    .photosets {
        grid-template-columns: repeat(3, 1fr);

        .photosets-image-container {
            &:nth-child(3), &:nth-child(4) {
                display: none;
            }

            &:last-child {
                grid-row: 1/span 2;
                grid-column: 2/span 2;
            }
        }
    }

    .posts {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 1rem;
    }
    
    .post {

        .post-header::after {
            background-image: linear-gradient(to bottom, transparent 20%, var(--background-color) 98%)!important;
        }

        .post-title {
            font-size: 1.5rem!important;
        }
        .post-icon {
            color: var(--hightlight);
            opacity: .8;
        }

        .post-content {
            font-size: 16px;
        }
    }
    
    .masonry {
        column-count: 2;
    }
    .video-list {
        grid-template-columns: repeat(2, 1fr);
    }

    #main:not(.index),
    .footer-container {
        width: 90%!important;
    }

    #main {
        margin-top: 5rem;
    }

    #footer {
        p {
            width: 100%!important;
            align-items: flex-start!important;
            flex-direction: column!important;
        }
    }

    .articles {
        .posts-item-description {
            display: none;
        }
        h3 span {
            -webkit-line-clamp: 2!important;
        }
    }

}

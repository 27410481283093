.widget {
    width: 90%;
    margin: 2rem auto 0;

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        h2 {
            position: relative;
            margin: 0;
            padding-left: 2.5rem;
            font-size: 1.7rem;
            line-height: 1;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 2px;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                background-color: var(--theme-color);
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0.6rem;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                background-color: var(--shallow-theme-color);
            }
        }

        a {
            line-height: 1;
            color: var(--text-color-primary);
            .transition-setting(box-shadow);

            &:hover {
                box-shadow: inset 0 -.5rem var(--shallow-theme-color);
            }
        }
    }
}

// 相册 widget
.photosets {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    .photosets-image-container {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &::before {
            content: '';
            float: left;
            padding-top: 100%;
        }

        &:last-child {
            grid-row: 1/span 2;
            grid-column: 3/span 2;
        }

        .photosets-image {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            filter: brightness(0.8);
            z-index: 0;
            .transition-all();
        }

        .photosets-description {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: .5rem 1rem;
            color: var(--hightlight);
            background-image: linear-gradient(45deg, var(--shallow-theme-color), var(--theme-color));
            background-size: 100% 0;
            background-position-y: bottom;
            .transition-all();

            span {
                display: -webkit-inline-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &:hover {

            .photosets-image {
                transform: scale(1.2);
                filter: brightness(1);
            }

            .photosets-description {
                padding: 1rem 1rem;
                background-size: 100% 100%;
            }
        }
    }

}

// 视频 widget
.videos, .video-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    .video-item {

        .video-image {
            display: block;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
    
            &::before {
                content: '';
                float: left;
                padding-top: calc((9 / 16) * 100%);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 0;
                .transition-all();
            }
        }

        .video-play {
            opacity: 0;
            font-size: 3rem;
            line-height: 1;
            color: var(--hightlight);
            .absolute-center();
        }

        .video-title {
            margin-top: 0.5rem;
            font-size: 0.8rem;

            span {
                display: -webkit-inline-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &:hover {

            .video-image img {
                transform: scale(1.2);
                filter: brightness(0.8);
            }
            .video-play {
                opacity: 1;
            }
        }
    }

}

// 文章列表 widget
.posts, .articles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;

    .posts-item {
        display: flex;
        overflow: hidden;

        &-cover {
            flex: 0 1 auto;
            position: relative;
            width: 30%;
            min-width: 30%;
            max-width: 30%;
            border-radius: 5px;
            margin-right: 0.5rem;
            overflow: hidden;

            &::before {
                content: '';
                float: left;
                padding-top: calc((9 / 16) * 100%);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 0;
                .transition-all();
            }
        }

        &-detail {
            flex: 0 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: .5rem 0;

            h3 {
                margin: 0 0 .5rem;

                span {
                    display: -webkit-inline-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }

        &-icon {
            color: var(--text-color-secondary);
            font-size: .9rem;

            span {
                position: relative;
                margin-right: 1rem;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -.6rem;
                    width: 2px;
                    height: 2px;
                    background-color: var(--text-color-secondary);
                    transform: translateY(-50%);
                }
            }
        }

        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
}
